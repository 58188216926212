
import { defineComponent, PropType, ref, watch } from 'vue';
import {Modal} from "bootstrap";
import { CampBtnGenerateExcel } from '@/components';
import { parseExcel } from '@/services/GenerateExcelService';
import { spreadSheet_columns, ICompetitorRelation } from "../Components/modalRelationStore.vue";
import { useLoaderStore } from "@/store/LoaderStore";

const PRINT_STORES: ICompetitorRelation[] = [
  {
    id_store: -1,
    id: -1,
    cpf: "",
    cnpj: ""
  }
]

export default defineComponent({
  name: "modalExportImportStores",
  components: {
    CampBtnGenerateExcel
  },
  props: {
    header: {
      type: String,
      default: "Importar ou exportar Excel"
    },
    toggle: {
      type: Boolean,
      default: false
    },
    relationDataInfo: {
      type: Array as PropType<ICompetitorRelation[]>,
      required: true
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}){
    let auxModal
    const file = ref<File | null>(null)
    const inputFile = ref<HTMLInputElement | null>(null)
    const dataTable = ref<Record<string, any>[]>([])
    const loaderStore = useLoaderStore()

    const openModal = (id: string) => {
      const auxElement = document.querySelector(`#${id}`)
      auxModal = new Modal(auxElement)
      auxModal.show()
    }

    function openInputArea() {
      inputFile.value!.click()
    }

    function preventBehavior(e) {
      e.stopPropagation()
      e.preventDefault()
    }

    function dropEvent(e) {
      preventBehavior(e)
      if (e.relationDataInfo.items) {
        [...e.relationDataInfo.items].forEach((item, i) =>{
          if (item.kind === 'file'){
            file.value = item.getAsFile()
          }
        })
      }else {
        [...e.relationDataInfo.files].forEach((fileParam, i) => {
          file.value = fileParam
        })
      }
    }

    async function uploadFile(event: Event) {
      const target = event.target as HTMLInputElement
      if (target.files) {
        file.value = target.files[0]
      }
    }

    async function getDataTable() {
      loaderStore.open()
      if (!file.value) {
        return
      }
      dataTable.value = await parseExcel(file.value)
      dataTable.value = dataTable.value.filter(item => Object.keys(item).length > 0)
      loaderStore.close()
    }

    
    function clearFile() {
      file.value = null
      dataTable.value = []
      if(inputFile.value){
        inputFile.value.value = ''
      }
    }
    
    function buttonClickEvent() {
      emit("event:getDataExcell", [...dataTable.value.map(el => ({...el})).filter(el => Object.keys(el).length > 0)])
      emit('dataTableUpdated', dataTable.value)
      clearFile()
    }

    watch(() => props.toggle, () => openModal("modalExportImportStores"))

    watch(() => file.value, async () => await getDataTable())

    watch(() => props.refresh, async () => clearFile())

    return {
      file,
      inputFile,
      openInputArea,
      preventBehavior,
      dropEvent,
      uploadFile,
      buttonClickEvent,
      PRINT_STORES,
      spreadSheet_columns,
      dataTable,
      clearFile
    }
  }
})

