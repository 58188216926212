
    import { defineComponent, ref, Ref, watch, defineProps } from "vue";
    import { IUserData } from "@/store/UsersStore";
    import axios, { AxiosError } from "axios";
    import useAlert from "@/composables/Alert";
    import modalExportImportStores from "./ModalExportImportStores.vue";
    import { CampBtnGenerateExcel } from "@/components";
    import { cloneDeep } from 'lodash';
    import { Modal } from "bootstrap";
    import { useLoaderStore } from "@/store/LoaderStore";


    export interface ICompetitorRelation {
      id_store: number | null,
      id: number | null,
      cpf: string | null,
      cnpj: string | null
    }

    export const spreadSheet_columns = {
      id_store: "id_store",
      cnpj: "cnpj",
      id: "id",
      cpf_competitor: "cpf_competitor"
    }

    export default defineComponent({
        name: "modalRelationCompetitor",
        props:{
            relationData: Object,
            isChangeProps: Boolean
        },
        components: {
          modalExportImportStores,
          CampBtnGenerateExcel
        },
        setup(props) {
            const modalRelationInfo = ref()
            const relationDataInfo = ref<any | never>([])
            const ownRelationInfo = ref()           
            const value1 = ref([])
            const options = ref<any>([])
            const isLoading = ref(false)
            const isLoadingButton = ref(false)
            const isLoadSelect = ref(false)
            const lenghtRealation = ref(0)
            const cpfCompetitor = ref('')
            const togglemodalExportImportStores = ref(false)
            const refreshmodalExportImportStores = ref(false)
            const dialogVisible = ref(false)
            const arrayNotValid = ref({ invalidFormat: [], notFound: [] })
            const editableInvalidFormatCNPJs = ref<string[]>([])
            const editableNotFoundCNPJs = ref<string[]>([])
            const loaderStore = useLoaderStore()
    
            const { showTimeAlert } = useAlert()

            function resetSelectValues() {
                value1.value = []
                lenghtRealation.value = 0
            }

            function updateArrayNotValid(list) {
                arrayNotValid.value = list
                editableInvalidFormatCNPJs.value = list.invalidFormat.slice()
                editableNotFoundCNPJs.value = list.notFound.slice()
            }

            async function onSubmitFunction(data) {
                isLoading.value = true
                try {
                const arrayRelation: any = []
                    value1.value.forEach(elem => {
                        arrayRelation.push({
                            cpf_competitor: relationDataInfo.value.length > 0 ? relationDataInfo.value[0].cpf_competitor : cpfCompetitor.value,
                            id_store: elem
                          })
                    })
               
                    const response = await axios.post('/api/postCompetitorStore', arrayRelation)

                    await getRelation()
                     geDataRelation()
                    resetSelectValues()
                } catch (error) {
                    if(error instanceof Error) {

                        showTimeAlert(error.message, "error")
                    }
                    
                } finally {
                    isLoading.value = false
                    lenghtRealation.value = relationDataInfo.value.length
                }
            }

            async function getRelation(loading: boolean | undefined = true) {
                if(loading){
                    isLoading.value = true
                }
                isLoadingButton.value = true
                try {
                    const response = await axios.get(`/api/getCompetitorStore/${ownRelationInfo.value.id}`)
                    relationDataInfo.value = response.data.data
                    if(relationDataInfo.value.length < 1) {
                      const response = await axios.get(`/api/getCompetitorView/${ownRelationInfo.value.id}`)
                      cpfCompetitor.value = response.data.data?.cpf
                    }
                } catch (error) {
                    if(error instanceof AxiosError) {
                      if(error.response) {
                        if(error.response.data.message !== "O vendedor não possui lojas cadastradas") {
                          showTimeAlert(error.response.data.message, "error")
                        }
                      } else {
                        showTimeAlert("Algo deu errado!", "error")
                      }
                    }
                } finally {
                    isLoading.value = false
                    isLoadingButton.value = false
                    lenghtRealation.value = relationDataInfo.value.length
                }
            }

            function openDragUpload(){
              refreshmodalExportImportStores.value = !refreshmodalExportImportStores.value
            }

            async function copyRelationFromExcell(data: Record<string, any>[]) {
              loaderStore.open()
              try{
                if(!data){
                  throw new Error("A planilha não parece compatível!")
                }else if(Array.isArray(data) && data.length < 1){
                  throw new Error("Arquivo não compatível!")
                }

                const userExcell = Object.keys(data[0])
                const defaultExcell = Object.values(spreadSheet_columns)
                userExcell.forEach(el1 => {
                  const incompatibleExcell = !(defaultExcell.some(el2 => el1 === el2))
                  if(incompatibleExcell){
                    throw new Error("Planilha não conforme!")
                  }
                })

                data.forEach(row => {
                  if(row.cnpj && typeof row.cnpj === 'number'){
                    row.cnpj = row.cnpj.toLocaleString('fullwide', { useGrouping: false })
                  }
                })

                const hasCNPJ = data.every(el => el[spreadSheet_columns.cnpj])
                if (!hasCNPJ) {
                  throw new Error("A planilha não contém CNPJ!")
                }
                
                const newRelationData = data.map(el => ({
                  cpf_competitor: cpfCompetitor.value || (relationDataInfo.value.length > 0 ? relationDataInfo.value[0].cpf_competitor : ''),
                  cnpj: String(el[spreadSheet_columns.cnpj])
                }))

                const existingIdStores = relationDataInfo.value.map(relation => relation.cnpj)
                const newFilteredRelationData = newRelationData.filter(relation => {
                  return !existingIdStores.includes(relation.cnpj)
                })

                if(newFilteredRelationData.length === 0){
                  showTimeAlert("Todos os registros dessa planilha já estão vinculados a esse cpf!")
                  return
                }

                const arrayRelation: any = []
                newFilteredRelationData.forEach(elem => {
                  arrayRelation.push({
                    cpf_competitor: elem.cpf_competitor,
                    cnpj: elem.cnpj
                  })
                })

                const cleanArrayRelation = cloneDeep(arrayRelation);
                const response = await axios.post('/api/postCompetitorStoreFromExcell', cleanArrayRelation)

                if (response.data.invalidCNPJs) {
                    const { invalidFormat, notFound } = response.data.invalidCNPJs

                    if((invalidFormat && invalidFormat.length > 0) || (notFound && notFound.length > 0)){
                      updateArrayNotValid(response.data.invalidCNPJs)
                      openModal("myModalCompetitorNotValidCnpj")
                    }
                } else {
                    openModal("myModalDetailRelationData");
                }

                await getRelation()
                      geDataRelation()
                      resetSelectValues()
              }catch (error: any) {
                if(error instanceof AxiosError){
                  showTimeAlert(error.response?.data.message, "error")
                }else if(error?.message){
                  showTimeAlert(error.message, "error")
                }else {
                  showTimeAlert("Algo deu errado", "error")
                }
              } finally {
                    lenghtRealation.value = relationDataInfo.value.length
                    loaderStore.close()
                }
            }

            let auxModal

            const openModal = (id: string) => {
              editableInvalidFormatCNPJs.value = [...arrayNotValid.value.invalidFormat]
              editableNotFoundCNPJs.value = [...arrayNotValid.value.notFound]
              const auxElement = document.querySelector(`#${id}`)
              auxModal = new Modal(auxElement)
              auxModal.show()
            }

            async function saveCNPJChanges() {
              loaderStore.open()
              const cnpjUpdates = [
                  ...editableInvalidFormatCNPJs.value.map(cnpj => ({ cnpj, 
                    cpf_competitor: cpfCompetitor.value || (relationDataInfo.value.length > 0 ? relationDataInfo.value[0].cpf_competitor : '') })),
                  ...editableNotFoundCNPJs.value.map(cnpj => ({ cnpj, 
                    cpf_competitor: cpfCompetitor.value || (relationDataInfo.value.length > 0 ? relationDataInfo.value[0].cpf_competitor : '') }))
              ]

              try {
                const response = await axios.post('/api/postCompetitorStoreFromExcell', cnpjUpdates)

                const modalElement = document.querySelector('#myModalCompetitorNotValidCnpj')
                const modal = new Modal(modalElement)
                await getRelation()
                      geDataRelation()
                      resetSelectValues()
                modal.hide()
                openModal("myModalDetailRelationData")
              }catch (error: any) {
                if(error instanceof AxiosError){
                  showTimeAlert(error.response?.data.message)
                }else if(error?.message){
                  showTimeAlert(error.message, "error")
                }else {
                  showTimeAlert("Algo deu errado", "error")
                }
              }finally {
                    lenghtRealation.value = relationDataInfo.value.length
                    loaderStore.close()
                }
            }

            watch(
                () => props.isChangeProps,
                () => {
                    ownRelationInfo.value = props.relationData
                    getRelation()
                    geDataRelation()
                }
            );  

            async function geDataRelation() {
                isLoadSelect.value = true
                options.value = []
                try {
                    const response = await axios.get('/api/getStore')
                    modalRelationInfo.value = response.data.data
                    // options.value = []
                    modalRelationInfo.value.forEach(element => {       
                        if(!relationDataInfo.value.some(elem => elem.id_store === element.id)){
                            options.value.push({
                                value: element.id,
                                label: element.fantasy_name + ' - ' + element.cnpj
                            })
                       }    

                    
                });
                isLoadSelect.value = false
                } catch (error) {
                    isLoadSelect.value = false
                }
            }
        
  
            async function deletRelation(idStore){
                isLoadingButton.value = true
                const cpf_competitor = relationDataInfo.value.length > 0 ? relationDataInfo.value[0].cpf_competitor : cpfCompetitor.value
                try {
                    const response = await axios.delete(`/api/deleteCompetitorStore/${cpf_competitor}/${idStore}`)
                    getRelation(false)
                    geDataRelation()
                   
                } catch (error) {

                } finally {
                    // isLoadingButton.value = false
                }
            }
      


       
            
            return {
                modalRelationInfo,
                ownRelationInfo,
                value1,
                options,
                onSubmitFunction,
                relationDataInfo,
                resetSelectValues,
                deletRelation,
                isLoading,
                isLoadSelect,
                isLoadingButton,
                lenghtRealation,
                togglemodalExportImportStores,
                refreshmodalExportImportStores,
                copyRelationFromExcell,
                openDragUpload,
                updateArrayNotValid,
                arrayNotValid,
                saveCNPJChanges,
                editableInvalidFormatCNPJs,
                editableNotFoundCNPJs
            }
        }

    })

